import Button from '$components/Button';
import Input from '$components/Input';
import Layout from '$components/Layout';
import useForm from '$hooks/useForm';
import useLoading from '$hooks/useLoading';
import useNotify from '$hooks/useNotify';
import useT from '$hooks/useT';
import classNames from '$lib/classNames';
import Joi from 'joi';
import { signIn } from 'next-auth/react';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import logo from '~/public/images/logo.png';

export default function SignIn() {
  const { t, locale } = useT();
  const { notify, notifications } = useNotify();
  const router = useRouter();
  const [loading, setLoading] = useLoading();
  const { value, setVal, getError, onFormSubmit } = useForm({
    defaultValue: {
      email: '',
      password: '',
    },
    validateMapping: {
      email: Joi.string()
        .email({ tlds: { allow: false } })
        .required(),
      password: Joi.string().required(),
    },
    errorMsg: {
      email: t('errors.EMAIL_IS_REQUIRED') + t('errors.EMAIL_VALIDATE_ERROR'),
      password: t('errors.PASSWORD_IS_REQUIRED'),
    },
    onSubmit: async ({ email, password }, isValid, errors) => {
      if (!isValid) {
        return;
      }
      try {
        setLoading(true);
        await signIn('credentials', {
          redirect: true,
          email,
          password,
        });
      } catch (error) {
        notify({ type: 'error', title: t('alert.signInFail') });
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <Layout noHeader className="flex flex-col items-center justify-center">
      <div className="w-[200px] h-[150px] relative">
        <Image src={logo} layout="fill" objectFit="contain" />
      </div>
      <h1 className="mb-5 md:text-2xl lg:text-3xl">{t('signIn')}</h1>
      <form
        className="flex w-full max-w-lg flex-col space-y-3 rounded-md bg-white p-4 shadow-md"
        onSubmit={onFormSubmit}
      >
        <Input
          name="email"
          label={t('email')}
          error={getError('email')}
          value={value.email}
          onChange={(e) => {
            setVal('email', e.target.value);
          }}
        />
        <Input
          name="password"
          type="password"
          label={t('password')}
          error={getError('password')}
          value={value.password}
          onChange={(e) => {
            setVal('password', e.target.value);
          }}
        />
        <Button type="submit" loading={loading}>
          {t('confirm')}
        </Button>
        <Link href="/auth/forget-password">
          <a className="self-center border-b text-sm">{t('forgetPassword')}</a>
        </Link>
        <div className="flex items-center space-x-3 self-end text-sm">
          <Link href="/auth/sign-in" locale="zh">
            <a
              className={classNames(
                locale == null || locale === 'zh'
                  ? 'border-b-2 border-green-900'
                  : 'text-neutral-400'
              )}
            >
              繁
            </a>
          </Link>
          <Link href="/auth/sign-in" locale="cn">
            <a
              className={classNames(
                locale == null || locale === 'cn'
                  ? 'border-b-2 border-green-900'
                  : 'text-neutral-400'
              )}
            >
              簡
            </a>
          </Link>
          <Link href="/auth/sign-in" locale="en">
            <a
              className={classNames(
                locale == null || locale === 'en'
                  ? 'border-b-2 border-green-900'
                  : 'text-neutral-400'
              )}
            >
              EN
            </a>
          </Link>
        </div>
      </form>
    </Layout>
  );
}
